import { makeAutoObservable } from 'mobx';
import { Configuration } from '../types/configuration';
import { JSON_MIME_TYPE } from '../types/google';
import { AuthenticationStore } from './authentication.store';

const CONFIG_FILE_NAME = 'config.json';

export class DriveStore {
  constructor(private authStore: AuthenticationStore) {
    makeAutoObservable(this);
    gapi.load('picker', () => {});
  }

  async getConfiguration(): Promise<Configuration | undefined> {
    const {
      result: { files },
    } = await gapi.client.drive.files.list({
      spaces: 'appDataFolder',
      fields: 'files(id, name)',
    });
    const configFile = files?.find(file => file.name === CONFIG_FILE_NAME);
    if (configFile?.id) {
      const { result } = await gapi.client.drive.files.get({
        fileId: configFile.id,
        alt: 'media',
      });
      return result as Configuration;
    }
  }

  async createConfigurationFile(config: Configuration) {
    const {
      result: { id },
    } = await gapi.client.drive.files.create({
      // @ts-ignore
      name: CONFIG_FILE_NAME,
      parents: ['appDataFolder'],
      mimeType: JSON_MIME_TYPE,
      fields: 'id, name, kind, size',
    });
    await fetch(`https://www.googleapis.com/upload/drive/v3/files/${id}`, {
      method: 'PATCH',
      headers: new Headers({
        Authorization: `Bearer ${this.authStore.authToken}`,
        'Content-Type': JSON_MIME_TYPE,
      }),
      body: new Blob([JSON.stringify(config)], { type: JSON_MIME_TYPE }),
    });
  }
}
