import { AuthenticationStore } from './authentication.store';
import { ConfigurationStore } from './configuration.store';
import { DriveStore } from './drive.store';

export class RootStore {
  authenticationStore = new AuthenticationStore();
  driveStore = new DriveStore(this.authenticationStore);
  configurationStore = new ConfigurationStore(
    this.authenticationStore,
    this.driveStore
  );
}
