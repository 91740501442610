import {
  Button,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useContext, useState } from 'react';
import { RootContext } from '..';

const Layout = styled('div')`
  flex-direction: column;
`;

const ContactConfiguration = styled(Box)`
  display: 'flex';
  flex-direction: 'row';
  padding-top: 2;
`;

export const SetupWizard: FunctionComponent = observer(() => {
  const { configurationStore } = useContext(RootContext);
  const [contactGroupName, setcontactGroupName] = useState('');

  return (
    <Layout>
      <Typography variant="h4">
        Laten we de applicatie even configureren!
      </Typography>
      <Stepper>
        <Step>
          <StepLabel>Contactpersonen</StepLabel>
        </Step>
      </Stepper>
      <TextField
        label="Label voor klanten"
        value={contactGroupName}
        onChange={({ target: { value } }) => setcontactGroupName(value)}
      />
      <ContactConfiguration>
        <Button
          onClick={() => configurationStore.initialize({ contactGroupName })}
        >
          Afronden
        </Button>
      </ContactConfiguration>
    </Layout>
  );
});
