import { action, makeAutoObservable } from 'mobx';

export class AuthenticationStore {
  auth?: gapi.auth2.GoogleAuth;
  authInitialized: Promise<void>;
  isSignedIn = false;

  constructor() {
    makeAutoObservable(this);

    this.authInitialized = Promise.all([
      new Promise(res => gapi.load('auth2', res)),
      new Promise(res => gapi.load('client', res)),
    ])
      .then(() => {
        gapi.auth2.init({
          client_id:
            '108651403449-o1051ie7a5ubi5ethgvsgbkp2hhj1k90.apps.googleusercontent.com',
          scope: [
            'email',
            'profile',
            'https://www.googleapis.com/auth/contacts',
            'https://www.googleapis.com/auth/drive.appdata',
          ].join(' '),
        });
        return gapi.client.init({
          discoveryDocs: [
            'https://people.googleapis.com/$discovery/rest',
            'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
          ],
        });
      })
      .then(() => {
        this.auth = gapi.auth2.getAuthInstance();
        return new Promise(resolve => {
          this.auth?.isSignedIn.listen(isSignedIn => {
            this.isSignedIn = isSignedIn;
            resolve();
          });
        });
      });
    // TODO: compare actual scopes to requested scopes and logout whenever
    // there's a difference (new scope has to be requested on next login)
  }

  get userName() {
    return this.auth?.currentUser.get().getBasicProfile().getName() || '';
  }

  get photoUrl() {
    return this.auth?.currentUser.get().getBasicProfile().getImageUrl() || '';
  }

  get authToken() {
    return (
      this.auth?.currentUser.get().getAuthResponse(true)?.access_token || ''
    );
  }

  @action async signIn() {
    while (!this.auth?.isSignedIn.get()) {
      (await this.auth?.signIn()) || Promise.resolve();
      await new Promise<void>(res => setTimeout(() => res(), 1000));
    }
    this.isSignedIn = true;
  }

  @action async signOut() {
    await this.authInitialized;

    this.auth!.signOut();
    // TODO: this method is useless as long as the app immediately signs you back in... Splash screen?
  }
}
