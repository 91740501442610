import React, { createContext, StrictMode } from 'react';
import { render } from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeOptions, ThemeProvider } from '@mui/material';
import { RootStore } from './stores/root.store';
import { RootRouter } from './routes/root.router';

export const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#ff8f00',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#310000',
      paper: '#731010',
    },
  },
  typography: {
    fontFamily: 'Do Hyeon',
  },
  shape: {
    borderRadius: 16,
  },
};

const rootStore = new RootStore();
export const RootContext = createContext<RootStore>(rootStore);

render(
  <StrictMode>
    <RootContext.Provider value={rootStore}>
      <ThemeProvider theme={theme}>
        <RootRouter />
      </ThemeProvider>
    </RootContext.Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
