import { action, makeAutoObservable, reaction } from 'mobx';
import { Configuration } from '../types/configuration';
import { AuthenticationStore } from './authentication.store';
import { DriveStore } from './drive.store';

export class ConfigurationStore {
  /**
   * undefined if not loaded yet
   * null if not available yet
   */
  private configuration?: Configuration | null;

  constructor(
    authenticationStore: AuthenticationStore,
    private driveStore: DriveStore
  ) {
    makeAutoObservable(this);

    reaction(
      () => authenticationStore.isSignedIn,
      async (isSignedIn) => {
        if (isSignedIn) {
          this.configuration = (await driveStore.getConfiguration()) || null;
        }
      }
    );
  }

  get isLoaded() {
    return this.configuration !== undefined;
  }

  get isInitialized() {
    return !!this.configuration;
  }

  get(configKey: keyof Configuration) {
    if (this.configuration?.[configKey]) {
      return this.configuration[configKey];
    }
    throw new Error('Config is not yet initialized!');
  }

  @action async initialize(config: Configuration) {
    await this.driveStore.createConfigurationFile(config);
    this.configuration = config;
  }
}
