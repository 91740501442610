import { observer } from 'mobx-react-lite';
import { FunctionComponent, useContext, useEffect } from 'react';
import { RootContext } from '..';
import { Layout } from '../shared/layout';

export const LoginScreen: FunctionComponent = observer(() => {
  const { authenticationStore } = useContext(RootContext);
  useEffect(() => {
    authenticationStore.signIn();
  }, [authenticationStore]);
  return <Layout />;
});
