import { observer } from 'mobx-react-lite';
import { FunctionComponent, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RootContext } from '..';
import { Layout } from '../shared/layout';
import { LoadingScreen } from './loading.screen';
import { LoginScreen } from './login.screen';
import { SetupWizard } from './setup.wizard';

export enum ROUTES {
  ROOT = '/',
}

export const RootRouter: FunctionComponent = observer(() => {
  const {
    authenticationStore: { isSignedIn },
    configurationStore: { isInitialized, isLoaded },
  } = useContext(RootContext);
  if (!isSignedIn) {
    return <LoginScreen />;
  }
  if (!isInitialized && !isLoaded) {
    return <LoadingScreen />;
  }
  if (!isInitialized && isLoaded) {
    return <SetupWizard />;
  }
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path={ROUTES.ROOT}>
            <>Hello</>
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
});
