import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { FunctionComponent } from 'react';

const CenterContentContainer = styled('div')`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingScreen: FunctionComponent = () => (
  <CenterContentContainer>
    <CircularProgress />
  </CenterContentContainer>
);
