// People API
export type Person = gapi.client.people.Person;
export type ContactGroup = gapi.client.people.ContactGroup;

// Drive API
export type File = gapi.client.drive.File;

// Drive constants
export const JSON_MIME_TYPE = 'application/json';
export const DOCS_MIME_TYPE = 'application/vnd.google-apps.document';
export const SHEETS_MIME_TYPE = 'application/vnd.google-apps.spreadsheet';
export class MimeTypeNotSupportedError extends Error {}
